









import { gql } from 'graphql-tag';
import { Component, mixins } from 'nuxt-property-decorator';

import { blockColorMixin } from '@/composables/block-color';

import { PageBlockMixin } from '@/modules/cms';
import { ArticleListBlockDataFragment } from '@/graphql/generated';
import { medium1x1ImageFragment } from '@/graphql/queries/image.fragments';
import { blockableBlockColorFragment } from '@/graphql/queries/block-color';

@Component({
  graphqlData: gql`
    fragment ArticleListBlockData on ArticleListBlock {
      id
      title
      items {
        title
        lead
        linkText
        linkUrl
        image {
          ...Medium1x1ImageData
        }
      }
      ...BlockColor
    }
    ${medium1x1ImageFragment}
    ${blockableBlockColorFragment}
  `,
})
export default class ArticleListBlock extends mixins(
  PageBlockMixin<ArticleListBlockDataFragment>(),
  blockColorMixin) {
  // TODO: Fetch real content
  get articles () {
    return this.data.items.map(article => ({
      to: article.linkUrl,
      title: article.title,
      description: article.lead,
      image: article.image,
      linkText: article.linkText,
    }));
  }

  // articles: ArticleLink[] = [];
}
