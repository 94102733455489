import { render, staticRenderFns } from "./ArticleLinksList.vue?vue&type=template&id=6cbfff08&"
import script from "./ArticleLinksList.vue?vue&type=script&lang=ts&"
export * from "./ArticleLinksList.vue?vue&type=script&lang=ts&"
import style0 from "./ArticleLinksList.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ArticleLink: require('/tmp/build_601978b3/src/components/misc/ArticleLink.vue').default,BlockWrapper: require('/tmp/build_601978b3/src/components/block/BlockWrapper.vue').default})
