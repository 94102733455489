


























import { Component, Vue, Prop } from 'nuxt-property-decorator';

import { IconArrowRight } from '@/icons';

@Component({
  components: {
    IconArrowRight,
  },
})
export default class BlockContentWithImage extends Vue {
  // TODO: add bg type
  @Prop(String)
  backgroundColor!: string

  @Prop(String)
  title!: string

  // TODO: add type
  @Prop({ type: Array, required: true })
  articles!: any
}
